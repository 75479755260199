<template>
    <div>
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="content">
            <div class="item">
                <div class="itembox" @click="goPage('earn')">
                    <div class="left">
                        <img src="../../assets/images/property.png" alt="">
                        <span>我的收益</span>
                    </div>
                    <div class="right"><img src="../../assets/images/right_arrow.png" alt=""></div>
                </div>
            </div>
            <div class="item" @click="goPage('myegg')">
                <div class="itembox">
                    <div class="left">
                        <img src="../../assets/images/egg.png" alt="">
                        <span>我的太阳</span>
                    </div>
                    <div class="right"><img src="../../assets/images/right_arrow.png" alt=""></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'
    
export default {
    data(){
        return{
            title:this.$route.meta.title
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .item{
        width: 100%;
        height: 40px;
        
        .itembox{
            width: 94%;
            height: 40px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left{
                img{
                    width: 26px;
                    height: 26px;
                    vertical-align: middle;
                }
                span{
                    display: inline-block;
                    font-size: 16px;
                    vertical-align:middle;
                    line-height: 26px;
                }
            }
            .right{
                img{
                    width: 16px;
                    height: 16px;
                }
            }
        }
        
        &:active{
            background-color: #ddd;
        }
    }
</style>